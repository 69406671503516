<template>
  <div class="content03">
    <h2>パスワードをお忘れの方</h2>
    <div class="wrap">
      <form action="">
        <dl>
          <dt>ご登録のメールアドレスを入力下さい。</dt>
          <dd><input type="text" class="mail" id="mail" v-model="mail" /></dd>
          <span v-show="!result">{{ message }}</span>
        </dl>
        <p class="submit">
          <input
            type="submit"
            value="送  信"
            v-on:click.prevent="remindSubmit()"
          />
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemindPass",
  data: function () {
    return {
      mail: "",
      result: "",
      message: "",
    };
  },
  methods: {
    remindSubmit() {
      this.$store.dispatch("sendPass", this.mail).then((response) => {
        if (response.data.result) {
          this.result = true;
          this.message = response.data.message;
          this.$router.push("/remind_pass/thanks");
        } else {
          this.result = false;
          this.message = response.data.message;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
